import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export interface ConsultationInfo {
  bookingId: string;
  type: string;
  name: string;
  roomName: string;
}

export default function PreJoinScreens() {
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const [step, setStep] = useState(Steps.deviceSelectionStep);
  const [consultationInfo] = useState<ConsultationInfo>(() => {
    if (!URLRoomName) {
      return {};
    }
    try {
      const decodedInfo = JSON.parse(decodeURIComponent(atob(URLRoomName)));
      return decodedInfo;
    } catch (error) {
      console.error('Error decoding consultation info:', error);
      return {};
    }
  });

  const [name, setName] = useState<string>('');
  const [roomName, setRoomName] = useState<string>('');
  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(consultationInfo.roomName);
      setName(consultationInfo.name);
      setStep(Steps.deviceSelectionStep);
    }
  }, [URLRoomName, consultationInfo]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStep(Steps.deviceSelectionStep);
  };

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          setName={setName}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
        />
      )}
      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen consultationInfo={consultationInfo} setStep={setStep} />
      )}
    </IntroContainer>
  );
}
